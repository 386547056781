import React from "react"
import styled, { css } from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const StyledWrap = styled.div`

`

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
      />
      <StyledWrap>
        <p>NotFound</p>
      </StyledWrap>
    </Layout>
  )
}

export default NotFoundPage
